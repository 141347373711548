import { Component, OnInit, AfterViewInit  } from '@angular/core';

@Component({
  selector: 'app-udgar-live',
  templateUrl: './udgar-live.component.html',
  styleUrls: ['./udgar-live.component.css']
})
export class UdgarLiveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

